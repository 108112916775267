import React, { useState, useEffect } from 'react';
import { FaChevronCircleLeft, FaEdit } from 'react-icons/fa';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import PageScaffold from '../PageScaffold/PageScaffold';

import styles from './index.module.css';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import ProviderApi from '../../Api/ProviderApi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { requiredField, zipLength } from '../../Utils/FieldValidation';
import FieldBSRenderFilePicker from '../Common/FieldBSRenderFilePicker';
import { State } from '../../ApiTypes/State';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { useAppSelector } from '../../Reducers/Store';
import ProviderFileTable from './ProviderFileTable';
import { toast } from 'react-toastify';
import { Provider } from '../../ApiTypes/Provider';
import { formatPhoneNumbers } from '../../Utils/InputFormatters';
import { AddEditProviderFormType } from './AddEditProviderFormType';
import { ProviderFile } from '../../ApiTypes/ProviderFile';
import { format } from 'date-fns';
import { useDocViewModal } from '../DocViewModal/useDocViewModal';
import DocViewModal from '../DocViewModal/DocViewModal';
import AddFileBasic from '../AddFileBasic/AddFileBasic';
import DocumentsApi from '../../Api/DocumentsApi';

export default function AddEditProvider() {
  let navigate = useNavigate();
  let { providerId } = useParams();
  const { states } = useAppSelector((state) => state.reference);
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(
    null
  );
  const [showAddFile, setShowAddFile] = useState<boolean>(false);

  const types = [
    { label: 'General', value: 'General' },
    { label: 'W9', value: 'W9' },
  ];

  const [showDelete, setShowDelete] = useState<boolean>(false);
  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  useEffect(() => {
    getProvider();
  }, [providerId]);

  const getProvider = () => {
    if (providerId && providerId !== 'new') {
      ProviderApi.getProviderForEdit(+providerId)
        .then((res) => {
          if (res.data) {
            setSelectedProvider(res.data);
          } else {
            toast.error('Provider not found');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get Provider');
        });
    }
  };

  const onSubmit = (values: AddEditProviderFormType) => {
    if (selectedProvider) {
      return updateProvider(values);
    } else {
      return addNewProvider(values);
    }
  };

  const updateProvider = (values: AddEditProviderFormType) => {
    const now = format(new Date(), 'yyyy-MM-dd');

    const provider: Provider = {
      providerId: values.providerId,
      taxId: values.taxId,
      companyName: values.companyName ? values.companyName.toUpperCase() : '',
      firstName: values.firstName,
      lastName: values.lastName,
      addressId: values.addressId,
      checkPayableTo: values.companyName
        ? values.companyName.toUpperCase()
        : '',
      phone: values.phone,
      fax: values.fax,
      importid: values.importid,
      importedproviderid: values.importedproviderid,
      importsource: values.importsource,
      importvendorid: values.importvendorid,
      lastUsedDate: values.lastUsedDate,
      recentStraightPay: values.recentStraightPay,
      deleted: values.deleted,
      hasLien: values.hasLien,
      lienFilePath: values.lienFilePath,
      dateLastModified: now,
      dateCreated: values.dateCreated,
      active: values.active,
      address: values.address,
      providerFiles: [],
    };

    return ProviderApi.updateProvider(provider)
      .then((res) => {
        if (res.data.success) {
          if (values.file?.file) {
            handleAddLien(
              values.file,
              +res.data.affectedEntityIdentifier,
              false
            );
          } else {
            getProvider();
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update provider');
      });
  };

  const addNewProvider = (values: AddEditProviderFormType) => {
    const hasLienToUpload = values?.file?.file;

    const now = format(new Date(), 'yyyy-MM-dd');

    const provider: Provider = {
      providerId: 0,
      taxId: values.taxId,
      companyName: values.companyName ? values.companyName.toUpperCase() : '',
      firstName: values.firstName,
      lastName: values.lastName,
      addressId: null,
      checkPayableTo: values.companyName
        ? values.companyName.toUpperCase()
        : '',
      phone: values.phone,
      fax: values.fax,
      importid: null,
      importedproviderid: null,
      importsource: 'Claims Screen',
      importvendorid: null,
      lastUsedDate: null,
      recentStraightPay: null,
      deleted: values.deleted,
      hasLien: values.hasLien,
      lienFilePath: null,
      dateLastModified: now,
      dateCreated: now,
      active: values.active,
      address: values.address,
      providerFiles: [],
    };

    return ProviderApi.addNewProvider(provider)
      .then((res) => {
        if (res.data.success) {
          if (hasLienToUpload) {
            handleAddLien(
              values.file,
              +res.data.affectedEntityIdentifier,
              true
            );
          } else {
            navigate(
              `/provider-maintenance/${res.data.affectedEntityIdentifier}`
            );
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to save provider');
      });
  };

  const handleAddLien = (
    fileObj: { file: File; name: string },
    providerId: number,
    navigateToProvider: boolean
  ) => {
    const { file } = fileObj;
    const fd = new FormData();
    let fileToUpload = file;
    fileToUpload = new File([file], `${file.name}`, { type: file.type });
    fd.append('providerId', `${providerId}`);
    fd.append('file', fileToUpload);
    return ProviderApi.uploadLien(fd)
      .then((res) => {
        if (res.data.success) {
          if (navigateToProvider) {
            navigate(
              `/provider-maintenance/${res.data.affectedEntityIdentifier}`
            );
          } else {
            getProvider();
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to upload lien');
      });
  };

  const handleViewLien = () => {
    DocumentsApi.getFileByPath({
      filePath: selectedProvider?.lienFilePath ?? '',
    })
      .then((res) => {
        if (res.data.file) {
          setFileDownload(res.data);
          setShowDocViewModal(true);
        } else {
          toast.error('Failed to get lien file');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get lien file');
      });
  };

  const handleNewDocument = (values: {
    name: string;
    file: { file: File; name: string };
    type: string;
  }) => {
    const { file } = values.file;
    const originalExtension = file.name.split('.')[1];
    const fileName =
      values.name && values.name.split('.').length === 2
        ? values.name
        : values.name
        ? `${values.name}.${originalExtension}`
        : file.name;

    const fd = new FormData();
    let fileToUpload = file;
    fileToUpload = new File([file], `${fileName}`, { type: file.type });
    fd.append('fileType', values.type ?? 'General');
    fd.append('providerId', `${providerId}`);
    fd.append('file', fileToUpload);
    return ProviderApi.uploadFile(fd)
      .then((res) => {
        if (res.data.success) {
          getProvider();
          setShowAddFile(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to upload lien');
      });
  };

  const handleNewFileClick = () => {
    setShowAddFile(true);
  };

  const handleDeleteFileClick = (file: ProviderFile) => {
    return ProviderApi.deleteProviderFile(file.id)
      .then((res) => {
        if (res.data.success) {
          getProvider();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete file');
      });
  };
  const handleViewFileClick = (file: ProviderFile) => {
    DocumentsApi.getFileByPath({
      filePath: file.filePath ?? '',
    })
      .then((res) => {
        if (res.data.file) {
          setFileDownload(res.data);
          setShowDocViewModal(true);
        } else {
          toast.error('Failed to get file');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get file');
      });
  };

  return (
    <PageScaffold>
      <div className='d-flex  mb-3 mt-3'>
        <Link to={`/provider-maintenance`}>
          <Button
            type='button'
            variant='primary'
            size='sm'
            className='button-icon-text'
          >
            <FaChevronCircleLeft /> Go Back
          </Button>
        </Link>
      </div>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaEdit className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Provider</h1>
          </div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={selectedProvider ? selectedProvider : {}}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className='d-flex justify-content-around align-items-center py-3'>
                <Field
                  name='active'
                  type='checkbox'
                  label='Active'
                  checked={values.active === true}
                  component={FieldBSRenderCheckbox}
                />
                <Field
                  name='deleted'
                  type='checkbox'
                  label='Deleted'
                  checked={values.deleted === true}
                  component={FieldBSRenderCheckbox}
                />
              </div>
              <div className={`${styles.grid3}`}>
                <Field
                  name='companyName'
                  type='text'
                  label='Company Name'
                  component={FieldBSRenderText}
                  validate={requiredField}
                />
                <Field
                  name='lastName'
                  type='text'
                  label='Last Name'
                  component={FieldBSRenderText}
                  //validate={requiredField}
                />
                <Field
                  name='firstName'
                  type='text'
                  label='First Name'
                  component={FieldBSRenderText}
                  // validate={requiredField}
                />
              </div>
              <div className={`${styles.grid4}`}>
                <Field
                  name='taxId'
                  type='text'
                  label='Tax Id'
                  validate={requiredField}
                  component={FieldBSRenderText}
                />
                <Field
                  name='hasLien'
                  type='checkbox'
                  label='Has Lien'
                  checked={values.hasLien === true}
                  component={FieldBSRenderCheckbox}
                />
                <div>
                  {values.lienFilePath && (
                    <Button
                      type='button'
                      variant='outline-primary'
                      size='sm'
                      onClick={handleViewLien}
                    >
                      View Lien
                    </Button>
                  )}
                </div>
                {values?.hasLien === true && (
                  <Field
                    name='file'
                    type='file'
                    component={FieldBSRenderFilePicker}
                  />
                )}
              </div>
              <div className={`${styles.grid2}`}>
                <Field
                  name='phone'
                  label='Phone'
                  type='text'
                  format={formatPhoneNumbers}
                  component={FieldBSRenderText}
                />
                <Field
                  name='fax'
                  label='Fax'
                  type='text'
                  format={formatPhoneNumbers}
                  component={FieldBSRenderText}
                />
              </div>
              <div className={`${styles.grid3}`}>
                <Field
                  name='address.addressName'
                  type='text'
                  label='Address Name'
                  component={FieldBSRenderText}
                  // validate={requiredField}
                />
                <Field
                  name='address.address1'
                  type='text'
                  label='Address 1'
                  component={FieldBSRenderText}
                  // validate={requiredField}
                />
                <Field
                  name='address.address2'
                  type='text'
                  label='Address 2'
                  component={FieldBSRenderText}
                />

                <Field
                  name='address.city'
                  type='text'
                  label='City'
                  component={FieldBSRenderText}
                  // validate={requiredField}
                />
                <Field
                  name='address.state'
                  label='State'
                  options={states}
                  optionMethod={(options: State[]) =>
                    options.map((o) => (
                      <option key={o.stateAbbr} value={o.stateAbbr}>
                        {o.stateAbbr}
                      </option>
                    ))
                  }
                  component={FieldBSRenderSelect}
                  // validate={requiredField}
                />
                <Field
                  name='address.zip'
                  type='text'
                  label='Zip'
                  validate={zipLength}
                  component={FieldBSRenderText}
                  maxLength={10}
                />
              </div>
              <div className='d-flex justify-content-center align-items-center py-3'>
                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : providerId !== 'new' ? (
                    'Update'
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <>
        {providerId !== 'new' && (
          <ProviderFileTable
            handleDelete={handleDeleteFileClick}
            handleView={handleViewFileClick}
            handleAdd={handleNewFileClick}
            data={selectedProvider?.providerFiles ?? []}
          />
        )}
      </>
      <AddFileBasic
        types={types}
        handleAddDocument={handleNewDocument}
        show={showAddFile}
        setShow={setShowAddFile}
      />
      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
    </PageScaffold>
  );
}
