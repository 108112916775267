import { CellContext } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { ScanDocSimple } from '../../../ApiTypes/ScanDocSimple';
import { useAppSelector } from '../../../Reducers/Store';

export default function EditableTextCell({
  getValue,
  row,
  column,
  table,
}: CellContext<ScanDocSimple, unknown>) {
  const { userModel } = useAppSelector((state) => state.user);
  let initialValue = getValue();

  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const onBlur = () => {
    table.options.meta?.updateData(row.index, column.id, value);
  };
  if (row.getIsSelected() && !table.options.meta?.enableMultiple) {
    return (
      <input
        type='text'
        value={value as string}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        style={{ width: '100%' }}
      />
    );
  } else {
    if (getValue()) {
      return (
        <div
          onClick={() => {
            if (
              row.original.isAdmin === false ||
              (row.original.isAdmin === true &&
                row.original.uploadedBy.toLowerCase() ===
                  userModel?.user?.userId.toLowerCase())
            )
              row.toggleSelected();
          }}
        >
          {(getValue() as string) ?? ''}
        </div>
      );
    } else {
      return (
        <div
          onClick={() => {
            if (
              row.original.isAdmin === false ||
              (row.original.isAdmin === true &&
                row.original.uploadedBy.toLowerCase() ===
                  userModel?.user?.userId.toLowerCase())
            )
              row.toggleSelected();
          }}
        >
          -
        </div>
      );
    }
  }
}
