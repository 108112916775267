import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaEdit } from 'react-icons/fa';
import { Adjuster } from '../../../ApiTypes/Adjuster';
import { InsuranceCompany } from '../../../ApiTypes/InsuranceCompany';
import { State } from '../../../ApiTypes/State';
import { useAppSelector } from '../../../Reducers/Store';
import { centsValidation, zipLength } from '../../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../../Utils/InputFormatters';
import FieldBSRenderCheckbox from '../../Common/FieldBSRenderCheckbox';
import FieldBSRenderMoney from '../../Common/FieldBSRenderMoney';
import FieldBSRenderSelect from '../../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea';
import styles from './index.module.css';
import NewClaimEmailDocsTable from '../../NewClaimEmailDocs/NewClaimEmailDocsTable';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { TextMessageTemplate } from '../../../ApiTypes/TextMessageTemplate';
import TextLogApi from '../../../Api/TextLogApi';
import { toast } from 'react-toastify';
import UserApi from '../../../Api/UserApi';
import { EntityUser } from '../../../ApiTypes/EntityUser';

export default function TextMessageTemplateModal({
  show,
  setShow,
  selectedTemplate,
  getTextMessageTemplateList,
  isAdmin,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  selectedTemplate: TextMessageTemplate | null;
  getTextMessageTemplateList: () => void;
  isAdmin: boolean;
}) {
  const { insuranceCompanies } = useAppSelector((state) => state.reference);
  const { userModel } = useAppSelector((state) => state.user);
  const [usersList, setUsersList] = useState<EntityUser[]>([]);

  const getUsersList = () => {
    UserApi.getUsersList()
      .then((res) => {
        setUsersList(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to users list');
      });
  };

  useEffect(() => {
    getUsersList();
  }, []);

  const onSubmit = (values: TextMessageTemplate) => {
    return TextLogApi.addUpdateTextMessageTemplate(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          setShow(false);
          getTextMessageTemplateList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update');
      });
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='TextMessageTemplateModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='TextMessageTemplateModal-modal'
        >
          <FaEdit className='pe-1' /> Text Template
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={
              selectedTemplate
                ? selectedTemplate
                : { createdBy: !isAdmin ? userModel?.user?.userId : null }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <Field
                      name='name'
                      type='text'
                      label='Name'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='textBody'
                      label='Body'
                      rows={3}
                      component={FieldBSRenderTextArea}
                    />
                    {isAdmin && (
                      <Field
                        name='createdBy'
                        label='User'
                        options={usersList}
                        optionMethod={(options: EntityUser[]) =>
                          options.map((o) => (
                            <option key={o.userId} value={o.userId}>
                              {o.userName}
                            </option>
                          ))
                        }
                        component={FieldBSRenderSelect}
                      />
                    )}
                    {isAdmin && (
                      <Field
                        name='insurCoId'
                        label='Company'
                        options={insuranceCompanies}
                        optionMethod={(options: InsuranceCompany[]) =>
                          options.map((o) => (
                            <option key={o.accountnumber!} value={o.insurcoid!}>
                              {`${o.accountnumber} - ${o.shortname}`}
                            </option>
                          ))
                        }
                        component={FieldBSRenderSelect}
                      />
                    )}
                  </div>
                  <div className='d-flex justify-content-around align-items-center py-3'>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
