import React, { useEffect } from 'react';
import { Container, Modal, Spinner } from 'react-bootstrap';
import { Form as RFFForm, Field } from 'react-final-form';
import { Button, Form } from 'react-bootstrap';
import { calculateAge, displayDateOnly } from '../../Utils';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import styles from './index.module.css';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { Claimant } from '../../ApiTypes/Claimant';
import { toast } from 'react-toastify';
import { requiredField } from '../../Utils/FieldValidation';
import { formatNumbers } from '../../Utils/InputFormatters';
import { fetchThirdPartyApiservices } from '../../Actions/ReferenceActions';
import { ThirdPartyApiservice } from '../../ApiTypes/ThirdPartyApiservice';
import { NaviRequest } from '../../ApiTypes/NaviRequest';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import { Claim } from '../../ApiTypes/Claim';
import { Employer } from '../../ApiTypes/Employer';
import ThirdPartyApi from '../../Api/ThirdPartyApi';
import { ScanDocSimple } from '../../ApiTypes/ScanDocSimple';

export default function TriStarrInvestigationsModal({
  show,
  setShow,
  claim,
  claimant,
  employer,
  docs,
}: {
  show: boolean;
  setShow: (value: boolean) => void;
  claim: Claim | null;
  claimant: Claimant | null;
  employer: Employer | null;
  docs: ScanDocSimple[];
}) {
  const dispatch = useAppDispatch();
  const { userModel } = useAppSelector((state) => state.user);
  const { thirdPartyApiservices } = useAppSelector((state) => state.reference);

  useEffect(() => {
    getReferences();
  }, []);

  const getReferences = async () => {
    if (!thirdPartyApiservices.length) {
      await Promise.all([dispatch(fetchThirdPartyApiservices())]);
      // setLoading(false);
    } else {
      // setLoading(false);
    }
  };

  const onSubmit = (values: NaviRequest) => {
    console.log(values);
    return ThirdPartyApi.sendThirdPartyInfo(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Successfully Sent');
          setShow(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failure to send to 3rd party');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='sm'
      onHide={() => setShow(false)}
      dialogClassName={styles.largeModal}
      aria-labelledby='Tristarr-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Tristarr-Form-modal'>
          Tri-Starr Investigations
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={
              claim && claimant
                ? {
                    thirdPartyAPIId: 1,
                    claimNo: claim?.claimNo,
                    subjectFirstName: claimant.firstName,
                    subjectLastName: claimant.lastName,
                    subjectMiddleName: claimant.middleName,
                    subjectSSN: claimant.ssn,
                    subjectAddress: claimant.mainAddress?.address1,
                    subjectAddress2: claimant.mainAddress?.address2,
                    subjectCity: claimant.mainAddress?.city,
                    subjectState: claimant.mainAddress?.state,
                    subjectZip: claimant.mainAddress?.zip,
                    subjectCounty: claim.accidentCounty,
                    subjectPhone: claimant.homePhone,
                    subjectPhoto: null,
                    subjectGender: claimant.sex,
                    subjectRace: '',
                    subjectAge: calculateAge(claimant.dob ?? '').toString(),
                    subjectOccupation: claim.occupation,
                    subjectDOB: claimant.dob,
                    injuryDescription: claim.description,
                    injuryDate: claim.injuryDate,
                    // instructions: '',
                    requestorEmail: userModel?.user?.emailAddress,
                    requestorPhone: userModel?.user?.phoneNumber,
                    requestorFirstName: userModel?.adjuster?.firstName,
                    requestorLastName: userModel?.adjuster?.lastName,
                    // caseType: null,
                    budgetDays: 2,
                    // budgetDollars: claimant.mainAddress,
                    claimType: claim.claimTypeId,
                    insuredName: employer?.name,
                    insuredContactName: employer?.name,
                    insuredContactEmail: employer?.emailToNotify,
                    wC1: null,
                    additionalFile1: null,
                    additionalFile2: null,
                    additionalFile3: null,
                  }
                : {}
            }
            render={({ handleSubmit, form, values, pristine, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={`${styles.topGrid}`}>
                    {/* Labels for Claim Number, Claimant Name, Claimant DOB, InsurCoName, EmployerName */}
                    <div>Claim Number: {claim?.claimNo}</div>
                    <div>
                      Claimant Name: {claimant?.firstName} {claimant?.lastName}
                    </div>
                    <div>
                      Claimant DOB: {displayDateOnly(claimant?.dob ?? '')}
                    </div>
                    <div>Insurance Co: {employer?.name}</div>
                    <div>Employer: {employer?.name}</div>
                  </div>
                  <div className='d-flex p-3 gap-5'>
                    <div className={`${styles.width50}`}>
                      <Field
                        name='caseType'
                        label='Service'
                        options={thirdPartyApiservices}
                        optionMethod={(options: ThirdPartyApiservice[]) =>
                          options.map((o) => (
                            <option key={o.id} value={o.serviceValue ?? 0}>
                              {o.serviceName}
                            </option>
                          ))
                        }
                        component={FieldBSRenderSelect}
                        validate={requiredField}
                      />
                    </div>
                    <div className={`${styles.width50}`}>
                      <Field
                        name='budgetDays'
                        type='text'
                        label='Days'
                        component={FieldBSRenderText}
                        format={formatNumbers}
                        required
                      />
                    </div>
                  </div>
                  <div className={`${styles.width100}`}>
                    <Field
                      name='instructions'
                      type='text'
                      rows={3}
                      label='Instructions'
                      component={FieldBSRenderTextArea}
                    />
                  </div>
                  <fieldset>
                    <legend>Documents</legend>
                    {docs.map((d) => (
                      <Field
                        key={`${d.docid}`}
                        name='docIds'
                        label={d.nameAndDatesText}
                        type='checkbox'
                        value={d.docid}
                        // checked={values.docIds.includes(d.docid)}
                        component={FieldBSRenderCheckbox}
                      />
                    ))}
                  </fieldset>
                  <div className={styles.claimantFormButtonDiv}>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                    <Button
                      type='button'
                      size='sm'
                      variant='secondary'
                      onClick={() => {
                        form.reset();
                        form.restart();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
