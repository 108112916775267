import { CellContext } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { ScanDocSimple } from '../../../ApiTypes/ScanDocSimple';
import { useAppSelector } from '../../../Reducers/Store';

export default function EditableDocTypeCell({
  getValue,
  row,
  column,
  table,
}: CellContext<ScanDocSimple, unknown>) {
  const { userModel } = useAppSelector((state) => state.user);
  const { docTypes } = useAppSelector((state) => state.reference);
  let initialValue = getValue();

  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  if (row.getIsSelected() && !table.options.meta?.enableMultiple) {
    return (
      <select
        name='docTypeId'
        value={value as string}
        onChange={(e) => {
          setValue(e.target.value);
          table.options.meta?.updateData(row.index, column.id, e.target.value);
        }}
      >
        {docTypes.map((o) => (
          <option key={o.docTypeId} value={o.description ?? ''}>
            {o.description}
          </option>
        ))}
      </select>
    );
  } else {
    if (getValue()) {
      return (
        <div
          onClick={() => {
            if (
              row.original.isAdmin === false ||
              (row.original.isAdmin === true &&
                row.original.uploadedBy.toLowerCase() ===
                  userModel?.user?.userId.toLowerCase())
            )
              row.toggleSelected();
          }}
        >
          {(getValue() as string) ?? ''}
        </div>
      );
    } else {
      return (
        <div
          onClick={() => {
            if (
              row.original.isAdmin === false ||
              (row.original.isAdmin === true &&
                row.original.uploadedBy.toLowerCase() ===
                  userModel?.user?.userId.toLowerCase())
            )
              row.toggleSelected();
          }}
        >
          -
        </div>
      );
    }
  }
}
