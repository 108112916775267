import React, { useState } from 'react';
import decode from 'jwt-decode';

import { Form as RFFForm, Field } from 'react-final-form';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import logo from '../../Assets/gas-logo-silver-001.png';
import styles from './index.module.css';
import { requiredField } from '../../Utils/FieldValidation';
import { LoginRequest } from '../../ApiTypes/LoginRequest';
import AuthApi from '../../Api/AuthApi';
import { useAppDispatch } from '../../Reducers/Store';
import { fetchCurrentUser, setUserFromToken } from '../../Actions';
import { DecodedToken } from '../../Types/DecodedToken';
import PasswordInput from './PasswordInput';

export default function LoginScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showError, setShowError] = useState(false);

  const onSubmit = (values: LoginRequest) => {
    setShowError(false);
    return AuthApi.login(values)
      .then((res) => {
        localStorage.setItem('gasToken', res.data);
        const decoded: DecodedToken = decode(res.data);
        dispatch(
          setUserFromToken({
            userName:
              decoded[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
              ],
            exp: decoded.exp,
          })
        );
        dispatch(fetchCurrentUser());
        navigate('/reminders');
      })
      .catch((err) => {
        setShowError(true);
      });
  };

  return (
    <div className={styles.mainScreen}>
      {process.env.REACT_APP_DEPLOY_ENV === 'dev' && (
        <Alert variant='warning' className='m-0'>
          <Alert.Heading>DEV ENVIRONMENT</Alert.Heading>
        </Alert>
      )}
      {process.env.REACT_APP_DEPLOY_ENV === 'gl' && (
        <Alert variant='warning' className='m-0'>
          <Alert.Heading>GENERAL LIABILITY ENVIRONMENT</Alert.Heading>
        </Alert>
      )}
      <div
        className={`p-3 ${styles.loginHeader} d-flex justify-content-center align-items-center`}
      >
        <div className={`${styles.logoDiv} pb-3`}>
          <img src={logo} alt='Georgia Administrative Services Logo' />
        </div>
      </div>
      <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
        <div className={styles.loginForm}>
          <RFFForm
            onSubmit={onSubmit}
            render={({ handleSubmit, values, form, pristine, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='userName'
                    type='text'
                    label='Username'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='password'
                    type='password'
                    label='Password'
                    validate={requiredField}
                    component={PasswordInput}
                  />

                  {showError && (
                    <Alert variant='danger'>Authorization Failed!</Alert>
                  )}
                  <div className='d-flex justify-content-center mt-3'>
                    <Button
                      type='submit'
                      className={styles.gasBlue}
                      variant='light'
                    >
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
