import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { UserPriorityClaimCustom } from '../../ApiTypes/UserPriorityClaimCustom';
import { FaEye, FaTrash } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { displayDateOnly } from '../../Utils';
import styles from './index.module.css';

export default function PriorityClaimsTable({
  data,
  removePriority,
}: {
  data: UserPriorityClaimCustom[];
  removePriority: (p: UserPriorityClaimCustom) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<UserPriorityClaimCustom>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center align-items-center gap1Rem'>
            <Button
              type='button'
              variant='danger'
              size='sm'
              title='Remove Priority'
              onClick={() => {
                removePriority(row.original);
              }}
            >
              <FaTrash />
            </Button>
            <Link to={`/claims/${row.original.claimNo}`}>
              <Button
                type='button'
                variant='primary'
                size='sm'
                title='View Claim'
              >
                <FaEye />
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      header: 'Claim No',
      accessorKey: 'claimNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantFullName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Added',
      accessorKey: 'dateAdded',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <div className={styles.tableDiv}>
      <p className='fw-bold mb-0'>Priority Claims</p>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        showPagination={data.length > 10}
        noEmptyRows={true}
        selectableRow={true}
        highlightRow={true}
      />
    </div>
  );
}
