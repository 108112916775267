import { useState, useEffect } from 'react';
import { Container, Modal } from 'react-bootstrap';

import styles from './index.module.css';
import TextLogApi from '../../Api/TextLogApi';
import { TextMessageTemplate } from '../../ApiTypes/TextMessageTemplate';

export default function TextTemplatesModal({
  show,
  setShow,
  userId,
  insurcoId,
  setTextMessageTemplate,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  userId: string;
  insurcoId: number;
  setTextMessageTemplate: (textBody: string) => void;
}) {
  const [textTemplates, settextTemplates] = useState<TextMessageTemplate[]>([]);

  const getTextMessageTemplateList = () => {
    TextLogApi.getTextMessageTemplateList(userId, insurcoId)
      .then((res) => {
        settextTemplates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (show) {
      getTextMessageTemplateList();
    }
  }, [show]);

  return (
    <Modal
      centered
      show={show}
      size='sm'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='Text-Template-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Text-Template-modal'>
          Text Templates
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <h4>General</h4>
          <div className='w-100 d-flex gap-2 pb-3'>
            {textTemplates
              .filter((x) => x.createdBy === null && x.insurCoId === null)
              .map((temp) => (
                <div
                  className={`${styles.templateOptions}`}
                  onClick={() => {
                    setTextMessageTemplate(temp.textBody ?? '');
                    setShow(false);
                  }}
                >
                  {temp.name}
                </div>
              ))}
          </div>
          <h4>My Templates</h4>
          <div className='w-100 d-flex gap-2 pb-3'>
            {textTemplates
              .filter((x) => x.createdBy === userId)
              .map((temp) => (
                <div
                  className={`${styles.templateOptions}`}
                  onClick={() => {
                    setTextMessageTemplate(temp.textBody ?? '');
                    setShow(false);
                  }}
                >
                  {temp.name}
                </div>
              ))}
          </div>
          <h4>Company Templates</h4>
          <div className='w-100 d-flex gap-2 pb-3'>
            {textTemplates
              .filter((x) => x.insurCoId === insurcoId)
              .map((temp) => (
                <div
                  className={`${styles.templateOptions}`}
                  onClick={() => {
                    setTextMessageTemplate(temp.textBody ?? '');
                    setShow(false);
                  }}
                >
                  {temp.name}
                </div>
              ))}
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
