import {
  differenceInBusinessDays,
  differenceInCalendarDays,
  differenceInWeeks,
} from 'date-fns';
import { FormApi } from 'final-form';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Field } from 'react-final-form';
import RecurringPaymentsApi from '../../Api/RecurringPaymentsApi';
import { EdiPartOfBodyCode } from '../../ApiTypes/EdiPartOfBodyCode';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { useAppSelector } from '../../Reducers/Store';
import { parseDateTimesForServer, parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import FieldBSRenderPercent from '../Common/FieldBSRenderPercent';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';

import styles from './index.module.css';

export default function IncomeForm({
  values,
  form,
  transaction,
  showPaymentSection = true,
}: {
  form: FormApi<EdiTransaction, Partial<EdiTransaction>>;
  values: Partial<EdiTransaction>;
  transaction: EdiTransaction | null;
  showPaymentSection?: boolean;
}) {
  const { ediPartOfBodyCodes } = useAppSelector((state) => state.reference);

  const { claim } = useAppSelector((state) => state.currentClaimReducer);

  useEffect(() => {
    handleLoad();
  }, [claim, transaction]);

  const handleLoad = () => {
    if (!transaction) {
      if (claim?.disabilityDate) {
        form.change('disabilitydate', claim.disabilityDate);
      } else if (claim?.claimNo) {
        RecurringPaymentsApi.getRecurringPaymentsByClaimNumber(claim?.claimNo)
          .then((res) => {
            if (res.data.length > 0) {
              res.data.sort((a, b) => {
                const aDate = new Date(a.startDate);
                const bDate = new Date(b.startDate);
                return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
              });
              form.change('disabilitydate', res.data[0].startDate);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const calc = (start: Date, end: Date) => {
    let weeks = 0;
    let days = 0;
    let totalDays = 0;
    totalDays = differenceInCalendarDays(end, start) + 1;
    // totalDays += 1;
    // totalDays = differenceInDays(end, start);
    weeks = differenceInWeeks(end, start, { roundingMethod: 'floor' });
    days = totalDays % 7;
    if (totalDays % 7 === 0) {
      weeks += 1;
    }

    console.log({ totalDays, weeks, days });

    return {
      totalDays,
      weeks,
      days,
    };
  };

  return (
    <div>
      <h5 className='text-center'>B. Income Benefits</h5>

      <div className={`${styles.edi2Grid} ${styles.noRowGap}`}>
        <Field
          name='weeklyBenefitAmt'
          label={`${
            (values?.weeklyBenefitAmt ?? 0) > 0 ? '🗹' : '🗆'
          } Benefits are being paid at the rate of`}
          type='text'
          placeholder=' '
          initialValue={
            values.weeklyBenefitAmt ?? claim?.hourlyWage?.toString()
          }
          format={formatNumbers}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />

        <Field
          name='avgWeeklyWage'
          label='per week based on an average weekly wage of'
          placeholder=' '
          type='text'
          initialValue={
            values.avgWeeklyWage ?? claim?.avgWeeklyWage?.toString()
          }
          format={formatNumbers}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />

        <Field
          name='benefitStartDate'
          label='Payable From'
          parse={parseDateTimesForServer}
          component={FieldBSRenderDate}
          startOfDay={true}
          validate={requiredField}
        />
        <Field
          name='benefitThroughDate'
          label='Payable Through'
          parse={parseDateTimesForServer}
          component={FieldBSRenderDate}
          endOfDay={true}
          validate={requiredField}
          onBlur={() => {
            if (!values.benefitStartDate || !values.benefitThroughDate) {
              return;
            }

            const { totalDays, weeks, days } = calc(
              new Date(values.benefitStartDate),
              new Date(values.benefitThroughDate)
            );
            // var end = null;
            // var endString = values.benefitThroughDate;
            // end = new Date(endString);
            // var start = null;
            // start = new Date(values.benefitStartDate);

            // const { totalDays, weeks, days } = calc(start, end);

            form.change('ppdimpairmentWeeks', weeks);
            form.change('ppdimpairmentDays', days);
          }}
        />
      </div>
      <div className='d-flex align-items-center gap1Rem'>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            if (!values.benefitStartDate || !values.benefitThroughDate) {
              return;
            }
            // var end = null;
            // end = new Date(values.benefitThroughDate);
            // var start = null;
            // start = new Date(values.benefitStartDate);
            const { totalDays, weeks, days } = calc(
              new Date(values.benefitStartDate),
              new Date(values.benefitThroughDate)
            );
            // form.change('ppdimpairmentWeeks', weeks);
            // form.change('ppdimpairmentDays', days);

            // const { totalDays, weeks, days } = calc(start, end);
            form.change('ppdimpairmentWeeks', weeks);
            form.change('ppdimpairmentDays', days);
          }}
        >
          Calculate
        </Button>
        <Field
          name='ppdimpairmentWeeks'
          label='Weeks'
          placeholder=' '
          type='text'
          component={FieldBSRenderText}
        />
        <Field
          name='ppdimpairmentDays'
          label='Days'
          placeholder=' '
          type='text'
          component={FieldBSRenderText}
        />
      </div>
      <div>
        <div className='d-flex justify-content-between align-items-center w-100 border border-3 border-danger m-2'>
          <Field
            name='paymentReasonCode'
            type='radio'
            label='Temporary Total Disability'
            value='050'
            checked={values?.paymentReasonCode === '050'}
            component={FieldBSRenderCheckbox}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center w-100 border border-3 border-primary m-2'>
          <Field
            name='paymentReasonCode'
            type='radio'
            label='Temporary Partial Disability'
            value='070'
            checked={values?.paymentReasonCode === '070'}
            component={FieldBSRenderCheckbox}
          />
        </div>
        <div className='border border-3 border-info m-2 w-100'>
          <div className='d-flex align-items-center '>
            <Field
              name='paymentReasonCode'
              type='radio'
              label='Permanent Partial Disability of '
              value='030'
              checked={values?.paymentReasonCode === '030'}
              component={FieldBSRenderCheckbox}
            />
            <div className='mx-2'>
              <Field
                name='permImpairmentPercentage'
                label=' '
                placeholder=' '
                type='text'
                component={FieldBSRenderPercent}
              />
            </div>
            <p className='mb-0'> to</p>
          </div>
          <div className='ms-5'>
            <Field
              name='ppdimpairmentBodyPartCode'
              label=' '
              options={ediPartOfBodyCodes}
              optionMethod={(options: EdiPartOfBodyCode[]) =>
                options.map((o) => (
                  <option key={o.value} value={o.value!}>
                    {`${o.value}: ${o.name} - ${o.description}`}
                  </option>
                ))
              }
              component={FieldBSRenderSelect}
            />
          </div>
        </div>
      </div>
      <Field
        name='disabilitydate'
        label={`${
          values?.disabilitydate ? '🗹' : '🗆'
        } Current Benefit Date of Disability`}
        parse={parseDatesForServer}
        component={FieldBSRenderDate}
      />
      {showPaymentSection && (
        <div className={`${styles.edi3Grid}`}>
          <Field
            name='benefitCheckDate'
            label='Date of First Check'
            parse={parseDatesForServer}
            component={FieldBSRenderDate}
          />
          <Field
            name='paymentAmount'
            label='Check Amount'
            type='text'
            format={formatNumbers}
            parse={cleanMoney}
            component={FieldBSRenderMoney}
          />
          <Field
            name='dateSalaryPaid'
            label='Date Salary Paid'
            parse={parseDatesForServer}
            component={FieldBSRenderDate}
          />
        </div>
      )}
      <div className='form-check'>
        <label className='form-check-label'>
          <input
            name='no-penalty'
            type='checkbox'
            className='form-check-input'
            checked={
              !values.penaltyPercentage ||
              (values?.penaltyPercentage ?? 0) === 0
            }
            readOnly
          />
          Does not include a penalty
        </label>
      </div>
      <div className={`${styles.edi3Grid}`}>
        <div className='form-check'>
          <label className='form-check-label'>
            <input
              name='penalty'
              type='checkbox'
              className='form-check-input'
              checked={(values?.penaltyPercentage ?? 0) > 0}
              readOnly
            />
            Does include a penalty
          </label>
        </div>
        <Field
          name='penaltyPercentage'
          label=' '
          placeholder='Penalty percent'
          type='text'
          component={FieldBSRenderPercent}
        />
        <Field
          name='penaltyAmount'
          label='Penalty Amount'
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
      </div>
    </div>
  );
}
