import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaChartBar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import EdiTransactionApi from '../../../Api/EdiTransactionApi';
import { AvailableReport } from '../../../ApiTypes/ReportRequest';
import { SiteFunctionsEnum } from '../../../ApiTypes/SiteFunctions';
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store';
import { downloadCsv } from '../../../Utils';
import CheckRegisterModal from '../../CheckRegister/CheckRegisterModal';
import ClaimsSummaryReportModal from '../../ClaimsSummaryReport/ClaimsSummaryReportModal';
import ConvertQbToPpModal from '../../ConvertQbToPp/ConvertQbToPpModal';
import DeductibleBillingModal from '../../DeductibleBilling/DeductibleBillingModal';
import ExcessStatusModal from '../../ExcessStatus/ExcessStatusModal';
import FroiClaimsModal from '../../FroiClaims/FroiClaimsModal';
import FundAnalysisModal from '../../FundAnalysis/FundAnalysisModal';
import MeccExport from '../../MeccExport/MeccExport';
import MidlandsExport from '../../MidlandsExport/MidlandsExport';
import PremiumAnalysisSetup from '../../PremiumAnalysisSetup/PremiumAnalysisSetup';
import PremiumAndLossModal from '../../PremiumAndLoss/PremiumAndLossModal';
import SafetyNationalExport from '../../SafetyNationalExport/SafetyNationalExport';
import UssuExport from '../../UssuExport/UssuExport';
import YkkExport from '../../YkkExport/YkkExport';
import { format } from 'date-fns';
import {
  DevExpressReportRequest,
  DxAvailableReports,
  ExportTypes,
} from '../../../ApiTypes/DevExpressReportRequest';
import { FileDownload } from '../../../Types/FileDownLoad';
import ReportApi from '../../../Api/ReportApi';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import {
  getDxReport,
  useDocViewModal,
} from '../../DocViewModal/useDocViewModal';
import DocViewModal from '../../DocViewModal/DocViewModal';
import NcmReportSetup from '../../NcmReportSetup/NcmReportSetup';

export default function ReportsDropdown({
  setReportEnumValue,
  setShowReportRequestModal,
  setReportEnumValueDx,
  setShowDxReportRequestModal,
}: {
  setReportEnumValue: (v: number) => void;
  setShowReportRequestModal: (show: boolean) => void;
  setReportEnumValueDx: (v: number) => void;
  setShowDxReportRequestModal: (show: boolean) => void;
}) {
  const { userModel } = useAppSelector((state) => state.user);

  const [showDeductibleBilling, setShowDeductibleBilling] =
    useState<boolean>(false);
  const [showExcessStatus, setShowExcessStatus] = useState<boolean>(false);
  const [showClaimSummary, setShowClaimSummary] = useState<boolean>(false);
  const [showPremiumAndLoss, setShowPremiumAndLoss] = useState<boolean>(false);
  const [showFundAnalysis, setShowFundAnalysis] = useState<boolean>(false);
  const [showPremiumAnalysisSetup, setShowPremiumAnalysisSetup] =
    useState<boolean>(false);
  const [showCheckRegister, setShowCheckRegister] = useState<boolean>(false);
  const [showConvertQbToPp, setShowConvertQbToPp] = useState<boolean>(false);
  const [showFroiClaims, setShowFroiClaims] = useState<boolean>(false);
  const [showMeccExport, setShowMeccExport] = useState<boolean>(false);
  const [showUssuExport, setShowUssuExport] = useState<boolean>(false);
  const [showMidlandsExport, setShowMidlandsExport] = useState<boolean>(false);
  const [showSafetyNational, setShowSafetyNational] = useState<boolean>(false);
  const [showYkkExport, setShowYkkExport] = useState<boolean>(false);
  const [showNCMReport, setShowNCMReport] = useState<boolean>(false);
  const dxRequest: DevExpressReportRequest = {
    accountNumber: '',
    claimNumber: '',
    employerId: null,
    fromDate: null,
    asOfDate: null,
    insurCoId: 0,
    startYear: 0,
    endYear: 0,
    external: false,
    openOnly: false,
    report: DxAvailableReports.rptWCLossRun,
    severity: '',
    status: '',
    toDate: null,
    userId: '',
    minReserveAmount: 0,
    caseManagementId: 0,
    letterId: 0,
    policyId: 0,
    reserveWorksheetId: 0,
    eDITransactionId: 0,
    keyDocRequestId: 0,
    searchText: '',
    onlyClearedChecks: false,
    topInjuriesReportType: 0,
    topInjuriesReportFilter: '',
    workStatusId: 0,
    maskSSN: false,
    wC4Id: 0,
    incidentOnly: false,
    useEOMData: true,
    useClaimDataForWc1: false,
    showAdjusterInfo: true,
    treatyYear: 0,
    exportType: ExportTypes.PDF,
    nCMReportType: 1,
  };
  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  const downloadSubmittedStatus = async () => {
    try {
      const res = await toast.promise(EdiTransactionApi.getSubmittedStatus(), {
        pending: `Fetching Report`,
        success: 'Success',
        error: `Failed to fetch report`,
      });
      if (res.data.fileName !== 'Failed') {
        downloadCsv(res.data.fileName, res.data.file);
      } else {
        toast.error('Failed to create and download file');
      }
    } catch (error) {
      console.log(error);
      toast.error('Failed to create and download file');
    }
  };
  return (
    <Dropdown>
      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
      <Dropdown.Toggle
        variant='secondary'
        id='reports'
        className='button-icon-text'
      >
        <FaChartBar />
        Reports
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ClaimReports && f.enabled
        ) && (
          <Dropdown drop='end'>
            <Dropdown.Toggle
              variant='light'
              id='claim-reports'
              className='w-100 text-start bg-transparent'
            >
              Claim Reports
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Link to='/adjuster-statistics'>
                <Dropdown.Item as='button'>My Dashboard</Dropdown.Item>
              </Link>
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId ===
                    SiteFunctionsEnum.ScheduleLossRunsExternal && f.enabled
              ) && (
                <Link to='/automated-reporting'>
                  <Dropdown.Item as='button'>Automated Reporting</Dropdown.Item>
                </Link>
              )}
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId ===
                    SiteFunctionsEnum.OpenClaimListingByYear && f.enabled
              ) && (
                <Dropdown.Item
                  as='button'
                  onClick={() => {
                    setReportEnumValue(AvailableReport.ClaimListingByYear);
                    setShowReportRequestModal(true);
                  }}
                >
                  Open Claim Listing by Year
                </Dropdown.Item>
              )}
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId ===
                    SiteFunctionsEnum.OpenClaimListingByEmployer && f.enabled
              ) && (
                <Dropdown.Item
                  as='button'
                  onClick={() => {
                    setReportEnumValue(AvailableReport.ClaimListingByEmployer);
                    setShowReportRequestModal(true);
                  }}
                >
                  Open Claim Listing by Employer
                </Dropdown.Item>
              )}
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId ===
                    SiteFunctionsEnum.OpenClaimListingByAdjuster && f.enabled
              ) && (
                <Dropdown.Item
                  as='button'
                  onClick={() => {
                    setReportEnumValue(AvailableReport.ClaimListingByAdjuster);
                    setShowReportRequestModal(true);
                  }}
                >
                  Open Claim Listing by Adjuster
                </Dropdown.Item>
              )}
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId === SiteFunctionsEnum.LossRunReport &&
                  f.enabled
              ) && (
                <>
                  <Dropdown.Item
                    as='button'
                    onClick={() => {
                      setReportEnumValueDx(DxAvailableReports.rptLossRun);
                      setShowDxReportRequestModal(true);
                    }}
                  >
                    Loss Run Report by Member - Internal (new style)
                  </Dropdown.Item>
                </>
              )}
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId ===
                    SiteFunctionsEnum.LossRunReportByMemberExternal && f.enabled
              ) && (
                <>
                  <Dropdown.Item
                    as='button'
                    onClick={() => {
                      setReportEnumValueDx(DxAvailableReports.rptLossRun);
                      setShowDxReportRequestModal(true);
                    }}
                  >
                    Loss Run Report by Member - External (new style)
                  </Dropdown.Item>
                </>
              )}
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setReportEnumValue(
                    AvailableReport.LossRunByProgramAndDeptWithDetailForOpen
                  );
                  setShowReportRequestModal(true);
                }}
              >
                Loss Run by Program and Department with Detail for open Claims
                (EOM)
              </Dropdown.Item>
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId === SiteFunctionsEnum.LossRunByProgramNew &&
                  f.enabled
              ) && (
                <Dropdown.Item
                  as='button'
                  onClick={() => {
                    setReportEnumValueDx(DxAvailableReports.rptLossSummary);
                    setShowDxReportRequestModal(true);
                  }}
                >
                  Loss Run by Program - Summary (EOM) - New
                </Dropdown.Item>
              )}
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setReportEnumValueDx(DxAvailableReports.rpt17Line);
                  setShowDxReportRequestModal(true);
                }}
              >
                17 Line Report
              </Dropdown.Item>
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId === SiteFunctionsEnum.DiaryListing &&
                  f.enabled
              ) && (
                <Dropdown.Item
                  as='button'
                  onClick={() => {
                    setReportEnumValue(AvailableReport.DiaryReport);
                    setShowReportRequestModal(true);
                  }}
                >
                  Diary Listing
                </Dropdown.Item>
              )}
              <Dropdown.Item
                as='button'
                onClick={() => setShowDeductibleBilling(true)}
              >
                Deductible Report
              </Dropdown.Item>
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId ===
                    SiteFunctionsEnum.SendGEMCLossRunsReports && f.enabled
              ) && (
                <Link to='/send-gemc-loss-runs'>
                  <Dropdown.Item as='button'>
                    Send GEMC Loss Run Reports
                  </Dropdown.Item>
                </Link>
              )}
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setReportEnumValue(AvailableReport.PaymentsByPayCodeAsOf);
                  setShowReportRequestModal(true);
                }}
              >
                All Payment History as of
              </Dropdown.Item>
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setReportEnumValue(AvailableReport.ReservesHistoryAsOf);
                  setShowReportRequestModal(true);
                }}
              >
                All Reserve History as of
              </Dropdown.Item>
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setReportEnumValueDx(DxAvailableReports.rptTopInjuries);
                  setShowDxReportRequestModal(true);
                }}
              >
                Top Injuries
              </Dropdown.Item>
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setReportEnumValue(
                    AvailableReport.ClaimStatusReportAllClaimsCurrent
                  );
                  setShowReportRequestModal(true);
                }}
              >
                Claim Status Report
              </Dropdown.Item>
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setReportEnumValueDx(DxAvailableReports.rptClaimSummaryAT);
                  setShowDxReportRequestModal(true);
                }}
              >
                Claim Status Report AGT
              </Dropdown.Item>
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setReportEnumValueDx(DxAvailableReports.rptClaimStatusChange);
                  setShowDxReportRequestModal(true);
                }}
              >
                Claim Severity Change Report
              </Dropdown.Item>
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setReportEnumValueDx(
                    DxAvailableReports.rptAGTReserveDifference
                  );
                  setShowDxReportRequestModal(true);
                }}
              >
                Reserve History Report
              </Dropdown.Item>
              {userModel?.user?.isAdmin && (
                <Dropdown.Item
                  as='button'
                  onClick={() => {
                    setReportEnumValueDx(
                      DxAvailableReports.rptClaimsCountByAdjuster
                    );
                    setShowDxReportRequestModal(true);
                  }}
                >
                  Claims Count by Adjuster
                </Dropdown.Item>
              )}
              <Link to='/adjuster-diary-report'>
                <Dropdown.Item as='button'>Adjuster Diary Report</Dropdown.Item>
              </Link>

              <Dropdown drop='end'>
                <Dropdown.Toggle
                  variant='light'
                  id='EOM-reports'
                  className='w-100 text-start bg-transparent'
                >
                  EOM
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    as='button'
                    onClick={() => setShowClaimSummary(true)}
                  >
                    Claims Summary Report
                  </Dropdown.Item>
                  <Dropdown.Item
                    as='button'
                    onClick={() => {
                      setShowPremiumAndLoss(true);
                    }}
                  >
                    Premium and Loss
                  </Dropdown.Item>
                  <Dropdown.Item
                    as='button'
                    onClick={() => setShowFundAnalysis(true)}
                  >
                    Fund Analysis Report
                  </Dropdown.Item>
                  <Dropdown.Item
                    as='button'
                    onClick={() => {
                      setShowPremiumAnalysisSetup(true);
                    }}
                  >
                    Premium Analysis
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.FinancialReports && f.enabled
        ) && (
          <Dropdown drop='end'>
            <Dropdown.Toggle
              variant='light'
              id='claim-reports'
              className='w-100 text-start bg-transparent'
            >
              Financial
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId ===
                    SiteFunctionsEnum.CheckRegisterLongFormat && f.enabled
              ) && (
                <Dropdown.Item
                  as='button'
                  onClick={() => {
                    setReportEnumValue(AvailableReport.CheckRegister);
                    setShowReportRequestModal(true);
                  }}
                >
                  Check Register-Long Format
                </Dropdown.Item>
              )} */}
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId ===
                    SiteFunctionsEnum.CheckRegisterCompactFormat && f.enabled
              ) && (
                <Dropdown.Item
                  as='button'
                  onClick={() => {
                    setReportEnumValue(AvailableReport.CheckRegisterNew);
                    setShowReportRequestModal(true);
                  }}
                >
                  Check Register-Compact Format
                </Dropdown.Item>
              )}
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId === SiteFunctionsEnum.SettlementReport &&
                  f.enabled
              ) && (
                <Dropdown.Item
                  as='button'
                  onClick={() => {
                    setReportEnumValue(AvailableReport.SettlementReport);
                    setShowReportRequestModal(true);
                  }}
                >
                  Settlement Report
                </Dropdown.Item>
              )}
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId ===
                    SiteFunctionsEnum.CheckRegisterAllAccounts && f.enabled
              ) && (
                <Dropdown.Item
                  as='button'
                  onClick={() => {
                    setReportEnumValue(
                      AvailableReport.CheckRegisterAllAccounts
                    );
                    setShowReportRequestModal(true);
                  }}
                >
                  Check Register - All Accounts
                </Dropdown.Item>
              )}
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId === SiteFunctionsEnum.CheckRegisterBasic &&
                  f.enabled
              ) && (
                <Dropdown.Item
                  as='button'
                  onClick={() => {
                    setShowCheckRegister(true);
                  }}
                >
                  Check Register Basic
                </Dropdown.Item>
              )}
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setShowConvertQbToPp(true);
                }}
              >
                Convert Positive Pay
              </Dropdown.Item>
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setReportEnumValueDx(DxAvailableReports.rptOutstandingChecks);
                  setShowDxReportRequestModal(true);
                }}
              >
                Outstanding Checks
              </Dropdown.Item>
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setReportEnumValueDx(DxAvailableReports.rpt17Line);
                  setShowDxReportRequestModal(true);
                }}
              >
                17 Line Report
              </Dropdown.Item>
              <Dropdown drop='end'>
                <Dropdown.Toggle
                  variant='light'
                  id='SPLC-IND-reports'
                  className='w-100 text-start bg-transparent'
                >
                  Check Register with SPLC and IND Only Options
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    as='button'
                    onClick={() => {
                      setReportEnumValue(
                        AvailableReport.CheckRegisterIncludeSplc
                      );
                      setShowReportRequestModal(true);
                    }}
                  >
                    Include SPLC
                  </Dropdown.Item>
                  <Dropdown.Item
                    as='button'
                    onClick={() => {
                      setReportEnumValue(
                        AvailableReport.CheckRegisterExcludeSplc
                      );
                      setShowReportRequestModal(true);
                    }}
                  >
                    Exclude SPLC
                  </Dropdown.Item>
                  <Dropdown.Item
                    as='button'
                    onClick={() => {
                      setReportEnumValue(AvailableReport.CheckRegisterSplcOnly);
                      setShowReportRequestModal(true);
                    }}
                  >
                    SPLC Only
                  </Dropdown.Item>
                  <Dropdown.Item
                    as='button'
                    onClick={() => {
                      setReportEnumValue(AvailableReport.CheckRegisterIndOnly);
                      setShowReportRequestModal(true);
                    }}
                  >
                    IND Only
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {userModel?.userSiteFunctions?.some(
          (f) => f.siteFunctionId === SiteFunctionsEnum.EDIReports && f.enabled
        ) && (
          <Dropdown drop='end'>
            <Dropdown.Toggle
              variant='light'
              id='EDI-reports'
              className='w-100 text-start bg-transparent'
            >
              EDI
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Link to='/edi-history'>
                <Dropdown.Item as='button'>
                  FROI's received with no SROI
                </Dropdown.Item>
              </Link>
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setShowFroiClaims(true);
                }}
              >
                Lost Time with no FROI
              </Dropdown.Item>
              <Link to='/edi-history'>
                <Dropdown.Item as='button'>
                  EDI Transaction Status
                </Dropdown.Item>
              </Link>
              <Dropdown.Item
                as='button'
                onClick={() => {
                  downloadSubmittedStatus();
                }}
              >
                EDI Submitted Status
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) => f.siteFunctionId === SiteFunctionsEnum.NCMReports && f.enabled
        ) && (
          <Dropdown drop='end'>
            <Dropdown.Toggle
              variant='light'
              id='NCM-reports'
              className='w-100 text-start bg-transparent'
            >
              NCM
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Link to='/ncm-dashboard'>
                <Dropdown.Item as='button'>NCM Dashboard</Dropdown.Item>
              </Link>
              <Dropdown.Item
                as='button'
                onClick={() => {
                  var date = new Date();
                  dxRequest.asOfDate = format(date, 'yyyy-MM-dd');
                  dxRequest.userId = userModel?.user?.userId ?? '';
                  dxRequest.report = DxAvailableReports.rptNCMReport;
                  getDxReport(dxRequest, setFileDownload, setShowDocViewModal);
                }}
              >
                NCM EOM Report - Current Month
              </Dropdown.Item>
              <Dropdown.Item
                as='button'
                onClick={() => {
                  var date = new Date();
                  dxRequest.asOfDate = format(
                    new Date(date.setMonth(date.getMonth() - 1)),
                    'yyyy-MM-dd'
                  );
                  dxRequest.userId = userModel?.user?.userId ?? '';
                  dxRequest.report = DxAvailableReports.rptNCMReport;
                  getDxReport(dxRequest, setFileDownload, setShowDocViewModal);
                }}
              >
                NCM EOM Report - Prior Month
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {showNCMReport && (
          <NcmReportSetup show={showNCMReport} setShow={setShowNCMReport} />
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.MECCExportData && f.enabled
        ) && (
          <Dropdown.Item
            as='button'
            onClick={() => {
              setShowMeccExport(true);
            }}
          >
            MECC Export Data
          </Dropdown.Item>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.USSUExportData && f.enabled
        ) && (
          <Dropdown.Item
            as='button'
            onClick={() => {
              setShowUssuExport(true);
            }}
          >
            USSU Export Data
          </Dropdown.Item>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.MIDMANExportData && f.enabled
        ) && (
          <Dropdown.Item
            as='button'
            onClick={() => {
              setShowMidlandsExport(true);
            }}
          >
            MIDMAN Export Data
          </Dropdown.Item>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.SafetyNationalExport &&
            f.enabled
        ) && (
          <Dropdown.Item
            as='button'
            onClick={() => {
              setShowSafetyNational(true);
            }}
          >
            Safety National Export
          </Dropdown.Item>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) => f.siteFunctionId === SiteFunctionsEnum.YKKExport && f.enabled
        ) && (
          <Dropdown.Item
            as='button'
            onClick={() => {
              setShowYkkExport(true);
            }}
          >
            YKK Export
          </Dropdown.Item>
        )}
        <Dropdown drop='end'>
          <Dropdown.Toggle
            variant='light'
            id='Document-reports'
            className='w-100 text-start bg-transparent'
          >
            Document Reports
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link to='/adjuster-statistics'>
              <Dropdown.Item as='button'>
                Adjuster Statistics Detail
              </Dropdown.Item>
            </Link>
            <Link to='/adjuster-summary'>
              <Dropdown.Item as='button'>
                Adjuster Statistics Summary
              </Dropdown.Item>
            </Link>
          </Dropdown.Menu>
        </Dropdown>
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ApproveReserves && f.enabled
        ) && (
          <Link to='/approve-reserves'>
            <Dropdown.Item as='button'>Approve Reserves</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.CompCompleteReport &&
            f.enabled
        ) && (
          <Dropdown drop='end'>
            <Dropdown.Toggle
              variant='light'
              id='CompComplete-reports'
              className='w-100 text-start bg-transparent'
            >
              CompComplete
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Link to='/compiqstats'>
                <Dropdown.Item as='button'>Daily Status Report</Dropdown.Item>
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ExcessDetailReport &&
            f.enabled
        ) && (
          <Link to='/excess-detail'>
            <Dropdown.Item as='button'>Excess Detail</Dropdown.Item>
          </Link>
        )}
        <Dropdown drop='end'>
          <Dropdown.Toggle
            variant='light'
            id='Medicare-reports'
            className='w-100 text-start bg-transparent'
          >
            Medicare
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link to='/medicare-report'>
              <Dropdown.Item as='button'>Generate Claim File</Dropdown.Item>
            </Link>
            <Link to='/medicare-results'>
              <Dropdown.Item as='button'>View Response Files</Dropdown.Item>
            </Link>
          </Dropdown.Menu>
        </Dropdown>
        <Link to='/productivity'>
          <Dropdown.Item as='button'>Productivity</Dropdown.Item>
        </Link>
      </Dropdown.Menu>
      {showExcessStatus && (
        <ExcessStatusModal
          show={showExcessStatus}
          setShow={setShowExcessStatus}
        />
      )}
      {showDeductibleBilling && (
        <DeductibleBillingModal
          show={showDeductibleBilling}
          setShow={setShowDeductibleBilling}
        />
      )}
      {showClaimSummary && (
        <ClaimsSummaryReportModal
          show={showClaimSummary}
          setShow={setShowClaimSummary}
        />
      )}
      {showPremiumAndLoss && (
        <PremiumAndLossModal
          show={showPremiumAndLoss}
          setShow={setShowPremiumAndLoss}
        />
      )}
      {showFundAnalysis && (
        <FundAnalysisModal
          show={showFundAnalysis}
          setShow={setShowFundAnalysis}
        />
      )}
      {showPremiumAnalysisSetup && (
        <PremiumAnalysisSetup
          show={showPremiumAnalysisSetup}
          setShow={setShowPremiumAnalysisSetup}
        />
      )}
      {showCheckRegister && (
        <CheckRegisterModal
          show={showCheckRegister}
          setShow={setShowCheckRegister}
        />
      )}
      {showConvertQbToPp && (
        <ConvertQbToPpModal
          show={showConvertQbToPp}
          setShow={setShowConvertQbToPp}
        />
      )}
      {showFroiClaims && (
        <FroiClaimsModal show={showFroiClaims} setShow={setShowFroiClaims} />
      )}
      {showMeccExport && (
        <MeccExport show={showMeccExport} setShow={setShowMeccExport} />
      )}
      {showUssuExport && (
        <UssuExport show={showUssuExport} setShow={setShowUssuExport} />
      )}
      {showMidlandsExport && (
        <MidlandsExport
          show={showMidlandsExport}
          setShow={setShowMidlandsExport}
        />
      )}
      {showSafetyNational && (
        <SafetyNationalExport
          show={showSafetyNational}
          setShow={setShowSafetyNational}
        />
      )}
      {showYkkExport && (
        <YkkExport show={showYkkExport} setShow={setShowYkkExport} />
      )}
      {/* <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      /> */}
    </Dropdown>
  );
}
