import { CellContext } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { ScanDocSimple } from '../../../ApiTypes/ScanDocSimple';
import { displayDateOnly } from '../../../Utils';
import { useAppSelector } from '../../../Reducers/Store';

export default function EditableDateCell({
  getValue,
  row,
  column,
  table,
}: CellContext<ScanDocSimple, unknown>) {
  const { userModel } = useAppSelector((state) => state.user);
  let initialValue = getValue();
  if (initialValue && (initialValue as string).indexOf('T') > 0) {
    initialValue = (initialValue as string).slice(
      0,
      (initialValue as string).indexOf('T')
    );
  } else {
    initialValue = '';
  }
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (
      column.id === 'serviceTo' &&
      (row.original.serviceTo === null ||
        row.original.serviceTo === '' ||
        row.original.serviceTo === row.original.serviceFrom) &&
      row.getIsSelected()
    ) {
      setValue(row.original.serviceFrom);
    }
  }, [row.original.serviceFrom]);

  const onBlur = () => {
    table.options.meta?.updateData(row.index, column.id, value);
    if (column.id === 'serviceFrom' && row.original.serviceTo === null) {
      table.options.meta?.updateData(row.index, 'serviceTo', value);
    }
  };
  if (row.getIsSelected() && !table.options.meta?.enableMultiple) {
    initialValue = getValue();
    return (
      <input
        type='date'
        value={value as string}
        onChange={(e) => {
          table.options.meta?.updateData(row.index, column.id, e.target.value);
          setValue(e.target.value);
        }}
        onBlur={onBlur}
      />
    );
  } else {
    // return displayDateOnly((getValue() as string) ?? '');
    if (getValue()) {
      return (
        <div
          onClick={() => {
            if (
              row.original.isAdmin === false ||
              (row.original.isAdmin === true &&
                row.original.uploadedBy.toLowerCase() ===
                  userModel?.user?.userId.toLowerCase())
            )
              row.toggleSelected();
          }}
        >
          {displayDateOnly((getValue() as string) ?? '')}
        </div>
      );
    } else {
      return (
        <div
          onClick={() => {
            if (
              row.original.isAdmin === false ||
              (row.original.isAdmin === true &&
                row.original.uploadedBy.toLowerCase() ===
                  userModel?.user?.userId.toLowerCase())
            )
              row.toggleSelected();
          }}
        >
          -
        </div>
      );
    }
  }
}
