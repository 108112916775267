import React, { useState, useEffect } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { FaCalendarAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import MedicareApi from '../../Api/MedicareApi';
import { MedicareClaimResult } from '../../ApiTypes/MedicareClaimResult';
import DetailsTable from './DetailsTable';
import styles from './index.module.css';

export default function MedicareClaimResultDetailPage({
  show,
  setShow,
  id,
}: {
  id: number | null;
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const [result, setResult] = useState<MedicareClaimResult | null>(null);

  useEffect(() => {
    getResult();
  }, [id]);

  const getResult = () => {
    if (id) {
      MedicareApi.getResult(id)
        .then((res) => {
          setResult(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get details');
        });
    } else {
      setResult(null);
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='xl'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='MedicareClaimResultDetailPage-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='MedicareClaimResultDetailPage-modal'
        >
          <FaCalendarAlt className='pe-1' /> Medicare Claim Result Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <DetailsTable data={result?.medicareClaimResultsDetails ?? []} />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
