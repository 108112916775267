import { useState, useEffect } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';

import styles from './index.module.css';
import TextLogApi from '../../Api/TextLogApi';
import { TextMessageTemplate } from '../../ApiTypes/TextMessageTemplate';
import { useAppSelector } from '../../Reducers/Store';
import TextMessageTemplateTable from './TextMessageTemplateMaintenance/TextMessageTemplateTable';
import { toast } from 'react-toastify';
import { FaPlusCircle } from 'react-icons/fa';
import TextMessageTemplateModal from './TextMessageTemplateMaintenance/TextMessageTemplateModal';

export default function TextTemplateManageModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const { userModel } = useAppSelector((state) => state.user);
  const [textTemplates, settextTemplates] = useState<TextMessageTemplate[]>([]);
  const [selectedTemplate, setselectedTemplate] =
    useState<TextMessageTemplate | null>(null);
  const [showAddEdit, setshowAddEdit] = useState<boolean>(false);

  const getTextMessageTemplateList = () => {
    TextLogApi.getTextMessageTemplateListCustom(userModel?.user?.userId!, 0)
      .then((res) => {
        settextTemplates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userModel?.user?.userId && show) {
      getTextMessageTemplateList();
    }
  }, [show]);

  const handleViewEdit = (template: TextMessageTemplate) => {
    setselectedTemplate(template);
    setshowAddEdit(true);
  };

  const handleDelete = (values: TextMessageTemplate) => {
    return TextLogApi.deleteTextMessageTemplate(values.id)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          getTextMessageTemplateList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='Text-Template-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Text-Template-modal'>
          My Text Templates
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div className='d-flex justify-content-center align-items-center pb-3'>
            <Button
              type='button'
              variant='primary'
              size='sm'
              className='button-icon-text'
              onClick={() => {
                setselectedTemplate(null);
                setshowAddEdit(true);
              }}
            >
              <FaPlusCircle /> New
            </Button>
          </div>
          <TextMessageTemplateTable
            data={textTemplates}
            handleViewEdit={handleViewEdit}
            handleDelete={handleDelete}
            isAdmin={false}
          />
          <TextMessageTemplateModal
            show={showAddEdit}
            setShow={setshowAddEdit}
            selectedTemplate={selectedTemplate}
            getTextMessageTemplateList={getTextMessageTemplateList}
            isAdmin={false}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
