import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaClipboardList, FaFolderOpen, FaPlusCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {
  fetchInsuranceCompanies,
  fetchInsuranceCompaniesForUser,
} from '../../../Actions/ReferenceActions';
import InsuranceCompanyApi from '../../../Api/InsuranceCompanyApi';
import { InsuranceCompany } from '../../../ApiTypes/InsuranceCompany';
import { useAppDispatch } from '../../../Reducers/Store';
import PageScaffold from '../../PageScaffold/PageScaffold';
import AddEditInsuranceCompany from './TextMessageTemplateModal';
import styles from './index.module.css';
import TextMessageTemplateTable from './TextMessageTemplateTable';
import ReferenceApi from '../../../Api/ReferenceApi';
import { TextMessageTemplate } from '../../../ApiTypes/TextMessageTemplate';
import TextLogApi from '../../../Api/TextLogApi';
import TextMessageTemplateModal from './TextMessageTemplateModal';

export default function TextMessageTemplateMaintenance() {
  const dispatch = useAppDispatch();
  const [templates, settemplates] = useState<TextMessageTemplate[]>([]);

  const [selectedTemplate, setselectedTemplate] =
    useState<TextMessageTemplate | null>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const handleViewEdit = (template: TextMessageTemplate) => {
    setselectedTemplate(template);
    setShowEdit(true);
  };

  const handleDelete = (values: TextMessageTemplate) => {
    return TextLogApi.deleteTextMessageTemplate(values.id)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          getTextMessageTemplateList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete');
      });
  };

  const getTextMessageTemplateList = () => {
    TextLogApi.getTextMessageTemplateListCustom('0', 0)
      .then((res) => {
        settemplates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTextMessageTemplateList();
  }, []);

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaClipboardList className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Text Templates</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center pb-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          className='button-icon-text'
          onClick={() => {
            setselectedTemplate(null);
            setShowEdit(true);
          }}
        >
          <FaPlusCircle /> New
        </Button>
      </div>
      <TextMessageTemplateTable
        data={templates}
        handleViewEdit={handleViewEdit}
        handleDelete={handleDelete}
        isAdmin={true}
      />
      <TextMessageTemplateModal
        show={showEdit}
        setShow={setShowEdit}
        selectedTemplate={selectedTemplate}
        getTextMessageTemplateList={getTextMessageTemplateList}
        isAdmin={true}
      />
    </PageScaffold>
  );
}
